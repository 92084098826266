.footer {
  background-color: #000000;

  .footer-docs {
    padding: 60px 0 70px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }
  .footer-docs2 {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    margin-top: 160px;
  }
}
