.header-view {
  padding: 16px 0;

  .logo {
    margin-left: 20px;
  }

  .navList {
    .navItem {
      &:not(:first-child) {
        margin-left: 48px;
      }
    }
  }

  .launchBtn {
    margin-left: 48px;
    margin-right: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .header-view {
    padding: 14px 0;
    .logo {
      margin-left: 16px;
    }

    .launchBtn {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}

.drawer-view {
  width: 100%;
  height: 100vh;
  background: #0d022f;

  .linkList,
  .close {
    margin-right: 40px;
  }
}
